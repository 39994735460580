/** @jsx jsx */
import Layout from '../layouts/Layout';
import { jsx } from '@emotion/core';
import {
  Card,
  Container,
  CardBody,
  CardTitle,
  CardHeader,
  Column,
  Row,
  Input,
  CardActions,
  CardAction,
} from '@nimles/react-web-components';
import { DefaultStaticQuery } from '../components/DefaultStaticQuery';
import { useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { resetPassword } from '@nimles/react-redux';
import { BannerSection } from '../components/banner/BannerSection';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import queryString from 'query-string';
import { PageModel } from '@nimles/models';
import { getImageUrl } from '../utils';

const ResetPasswordPage = ({ cms, location, site }) => {
  const { search } = location;

  const { code }: any = search && queryString.parse(search);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const page: PageModel = useMemo(
    () =>
      cms.pages.edges
        .map((edge) => edge.node)
        .find((page) => page.uniqueName === 'reset-password'),
    [cms.pages.edges]
  );

  const meta = {
    title: page?.title,
    description: page?.summary,
    image: getImageUrl(page?.imageId),
  };

  const handleResetPassword = async () => {
    await dispatch(resetPassword(password, code));
    navigate('/login');
  };

  return (
    <Layout
      header
      footer
      meta={meta}
      location={location}
      siteMetadata={site.siteMetadata}
    >
      <BannerSection graphic>
        <Container flex justify="center" display="flex">
          <Row justify="center">
            <Column xs={100} sm={90} md={70} lg={60} xl={50} xxl={40}>
              <Card>
                <CardHeader>
                  <CardTitle>Set new password</CardTitle>
                </CardHeader>
                <CardBody>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t('prop.password')}
                    type="password"
                  />
                </CardBody>
                <CardActions>
                  <CardAction primary raised onClick={handleResetPassword}>
                    Submit
                  </CardAction>
                </CardActions>
              </Card>
            </Column>
          </Row>
        </Container>
      </BannerSection>
    </Layout>
  );
};

const ResetPassword = ({ location }) => (
  <DefaultStaticQuery
    render={(props) => <ResetPasswordPage {...props} location={location} />}
  />
);

export default ResetPassword;
