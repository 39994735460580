import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const DefaultStaticQuery = ({ render }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            name
            title
            tenantId
            googleApiKey
          }
        }
        organizationManagement {
          organizations {
            edges {
              node {
                id
                name
                description
                uniqueName
                phoneNumber
                website
                bannerId
                address {
                  id
                  street
                  postalCode
                  city
                  state
                }
                location {
                  id
                  latitude
                  longitude
                }
                socialLinks
                tags
              }
            }
          }
          categories {
            edges {
              node {
                id
                name
                summary
                description
                uniqueName
                bannerId
                logoId
                tags
              }
            }
          }
        }
        cms {
          pages {
            edges {
              node {
                id
                organizationId
                pageType
                title
                uniqueName
                summary
                elements {
                  ...NimlesCMS_ElementPublicViewModelFragment
                  children {
                    ...NimlesCMS_ElementPublicViewModelFragment
                    children {
                      ...NimlesCMS_ElementPublicViewModelFragment
                      children {
                        ...NimlesCMS_ElementPublicViewModelFragment
                        children {
                          ...NimlesCMS_ElementPublicViewModelFragment
                          children {
                            ...NimlesCMS_ElementPublicViewModelFragment
                            children {
                              ...NimlesCMS_ElementPublicViewModelFragment
                              children {
                                ...NimlesCMS_ElementPublicViewModelFragment
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        pim {
          products {
            edges {
              node {
                id
                name
                uniqueName
                summary
                organizationId
              }
            }
          }
          productGroups {
            edges {
              node {
                id
                uniqueName
                name
                summary
                description
                parentId
                children {
                  id
                  uniqueName
                  name
                  summary
                  description
                  parentId
                }
              }
            }
          }
        }
      }

      fragment NimlesCMS_ElementPublicViewModelFragment on NimlesCMS_ElementPublicViewModel {
        id
        type
        uniqueName
        content
        contentLocalized
        contentId
        properties
      }
    `}
    render={render}
  />
);
